<template>
    <div>
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI标题提炼" />
                        <ai_upload @get_url="get_url" />
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />

    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title.vue'
import ai_upload from '@/components/ai_upload.vue'
import ai_text_content from '@/components/ai_text_content.vue'
import ai_button_file_data from '@/components/ai_button_file_data.vue'
export default {
    mounted() { this.getUserInfo() },
    components: {
        wordNav,
        ai_title,
        ai_upload,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            //type: '', // 行业
            //business: '',// 擅长领域
            //personal: '',// 个人荣誉
            form: {
                keyword: '', // 关键词
            },
            aiNumShow: false,
            aiPoupShow: false,
            contentData: {},
        }
    },
    methods: {
        get_url(url) {
            this.form.keyword = url
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }
        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            if (this.form.keyword != '') {
                this.curlPost('file/title_extraction', {
                    mobile: this.$user_info.mobile,
                    input_text: this.form.keyword,
                }).then(res => {
                    this.$refs.ai_button_file_data.loading_show()
                    if (res.data.code) {
                        this.contentData = res.data.data

                        // 扣费
                        this.curlPost('/api/users/money_log/spend', {
                            ai_id: res.data.data.id,
                            amount: res.data.data.money,
                        }).then(res => {
                            if (res.data.code) {
                                this.getUserInfo()
                            }
                        })
                    }
                })
            } else {
                return this.$message({
                    message: '禁止上传空文件!',
                    type: 'warning'
                });
            }
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai.scss'
</style>